import { qs } from 'url-parse';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	InvariantRoutes,
	MatchedRoute,
	MatchedInvariantRoute,
	Query,
	Routes,
	Route,
	InvariantRoute,
} from '../../types.tsx';
import matchRouteOrderIndependent from '../match-route-order-independent/index.tsx';
import unSafeMutateRoute from '../unsafe-mutate-route.tsx';
import execRouteMatching from './exec-route-matching.tsx';
import { matchRouteCache } from './utils.tsx';

const callWhenIdle = (callback: () => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (typeof window.requestIdleCallback === 'function') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.requestIdleCallback(callback);
	} else {
		setTimeout(callback, 3000);
	}
};

/**
 * Does the given `pathname` and `queryStr` match a route in `routes`.
 *
 * Heavily based on https://github.com/ReactTraining/react-router/blob/master/packages/react-router-config/modules/matchRoute.js
 *
 * Note: This does not support nested routes at this stage.
 */
export const matchRouteOrderDependent = <T extends Route | InvariantRoute>(
	routes: T[],
	pathname: string,
	queryParams: Query = {},
	basePath = '',
) => {
	const queryParamObject =
		typeof queryParams === 'string' ? (qs.parse(queryParams) as Query) : queryParams;

	const cachedMatch = matchRouteCache.get<T>(pathname, queryParamObject, basePath);
	if (cachedMatch && routes.includes(cachedMatch.route)) return cachedMatch;

	for (let i = 0; i < routes.length; i++) {
		const matchedRoute = execRouteMatching(routes[i], pathname, queryParamObject, basePath);
		if (matchedRoute) {
			unSafeMutateRoute(matchedRoute.route);

			matchRouteCache.set(pathname, queryParamObject, basePath, matchedRoute);

			return matchedRoute;
		}
	}

	return null;
};

const isLegacyRoutes = (routes: Routes): boolean => {
	// a naive way to check if the routes are legacy routes
	return routes[routes.length - 1]?.name === 'legacy-fallback';
};

export const matchInvariantRoute = (
	routes: InvariantRoutes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedInvariantRoute | null => {
	const useOrderIndependentMatching =
		isLegacyRoutes(routes) === false && fg('order-independent-route-matching');
	const matchRoute = useOrderIndependentMatching
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	return matchRoute(routes, pathname, queryParams, basePath);
};

/**
 * Performance optimisation to fast-match a single route
 * instead of looping thorugh all defined routes
 */
export const warmupMatchRouteCache = (
	route: Route,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
) => {
	// no need to check legacy routes because `warmupMatchRouteCache` is used for SPA only
	const matchRoute = fg('order-independent-route-matching')
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	return matchRoute([route], pathname, queryParams, basePath);
};

// check if the below routes are ACTUALLY used on production?
const routesToCheckUsage = ['servicedesk-reports-summary'];

const defaultMatchRoute = (
	routes: Routes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedRoute | null => {
	const useOrderIndependentMatching =
		isLegacyRoutes(routes) === false && fg('order-independent-route-matching');

	const matchRoute = useOrderIndependentMatching
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	const matchedRoute = matchRoute(routes, pathname, queryParams, basePath);

	if (!__SERVER__ && fg('log-route-servicedesk-reports-summary')) {
		if (matchedRoute?.route.name && routesToCheckUsage.includes(matchedRoute.route.name)) {
			log.safeInfoWithoutCustomerData('match_route', 'result', {
				name: matchedRoute.route.name,
			});
		}
	}

	if (useOrderIndependentMatching) {
		callWhenIdle(() => {
			const logLocation = 'match_route';
			const matchedRouteOld = matchRouteOrderDependent(routes, pathname, queryParams, basePath);
			if (matchedRouteOld && matchedRoute) {
				// if route object ref is different, log it
				if (matchedRouteOld.route !== matchedRoute.route) {
					log.safeInfoWithoutCustomerData(logLocation, 'diff', {
						newName: matchedRoute.route.name,
						newPath: matchedRoute.route.path,
						newQuery: matchedRoute.route.query?.length,
						oldName: matchedRouteOld.route.name,
						oldPath: matchedRouteOld.route.path,
						oldQuery: matchedRouteOld.route.query?.length,
					});
				}
			}
		});
	}

	// if there is an error from the order-independent matching, fallback to order-dependent matching
	if (useOrderIndependentMatching && matchedRoute === null) {
		return matchRouteOrderDependent(routes, pathname, queryParams, basePath);
	}
	return matchedRoute;
};

export default defaultMatchRoute;
